
import React, {useState} from "react";
import "./style.css";
import CardContent from '@mui/material/CardContent';
import { cardLink, cardView } from "../../hoc/ApolloProvider";

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';



interface CardProps {
  card: {
    id: string;
    title: string; 
    subtitle: string;
    data: string;
    category: string;
    linkto: string;
    img: string;
  };
  onCaseSelect: any;
}

const LinkCard = (props: CardProps) => {
  const { card, onCaseSelect } = props;

  //console.log( "cardData:"+card.data);

  const linkTo = (link:string) =>  {
    window.open(link, '_blank', "height=800,width=550");
  }
  const reload = () =>  {
    window.location.reload();
  }

  const cwidth = cardView().width;
  const cheight = cardView().height;
  
  return (
    <CardContent sx={{position: 'absolute',
    zIndex: 2,
    width: cwidth-13,
    height: cheight,
    top: 0,
    padding: '5px',
    }}>
      <div className="row hr-1">
        <div>{card.subtitle}</div>
      </div>
      <div className="row hr-2">
        <div  style={{whiteSpace: 'pre-line'}}>{card.title}</div>
      </div>
      {card.id === cardLink().cardid && <>
      <div className="row row-start meta-section">
       <div>{card.data}</div>
      </div>
      <Stack direction="column" justifyContent="center" spacing={{ xs: 2, sm: 1, md: 1 }}>
          <Button variant="contained" color="success" onClick={() => linkTo(card.linkto)} >
              {card.title}
          </Button>
          <Button variant="contained" color="success" onClick={() => reload()} >
               다 시 하 기
          </Button>
      </Stack>
      </>}
    </CardContent>
  );
};

export default LinkCard;
