
import "./style.css";

import { lazy, Suspense, useState} from 'react';
import Box from '@mui/material/Box';
import { cardView } from "../../hoc/ApolloProvider";
import useResize from "../../hook/useResize";

interface ResizableFrameProps {
  src: string;
  title: string;
  path: string;
  cid: string;
  status: number;
  nextCard: any;
  iframeStyles?: Record<string, unknown>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any;
}

const Form = ({
  src,
  title,
  path,
  cid,
  status,
  nextCard,
  iframeStyles = {},
  ...rest
}: ResizableFrameProps): JSX.Element => {


  //let cWidth = cardView().width;
    //let cHeight = cardView().height;
    const { width : cWidth, height: cHeight } = useResize();

  const Base = lazy(() => import('../Form/Base'));
  const Student = lazy(() => import('../Form/Student'));
  const Teacher = lazy(() => import('../Form/Teacher'));
  const Examiner = lazy(() => import('../Form/Examiner'));
  return (
    <Box component="div" height={(cHeight-80) + 'px'} width={(cWidth-20) + 'px'}>
      <Suspense fallback={null}>              
        { src === "../Form/Base" && <Base title={title} nextCard={(cIdx:number)=>nextCard(cIdx)} />}
        { src === "../Form/Student" && <Student  title={title} nextCard={(cIdx:number)=>nextCard(cIdx)}/>}
        { src === "../Form/Teacher" && <Teacher  title={title} nextCard={(cIdx:number)=>nextCard(cIdx)}/>}        
        { src === "../Form/Examiner" && <Examiner  title={title} nextCard={(cIdx:number)=>nextCard(cIdx)}/>}
      </Suspense>
    </Box>
  )

};

// maxWidth={viewportWidthMap[viewportWidth]}
export default Form;




