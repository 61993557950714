import React, {useState} from "react";
import "./style.css";

import CardContent from '@mui/material/CardContent';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from "@mui/material/ImageListItemBar";

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import { cardLink, cardView, connectorMQTT } from "../../hoc/ApolloProvider";
import { CardMedia, Grid } from "@mui/material";
import { SentimentSatisfiedAlt } from "@mui/icons-material";
import SelectList from "./SelectList";

interface CardProps {
  card: {
    id: string;
    title: string; 
    subtitle: string;
    data: string;
    category: string;
  };
  onCaseSelect: any;
}

const SwitchCard = (props: CardProps) => {
  const { card, onCaseSelect } = props;

  let mode = 1; // 1 image 

  if( card.data === null || card.data === "") return(<></>);

  //console.log( "cardData:"+card.data);
  const caseData = JSON.parse(card.data);
  
  caseData.cases.map((item:any) =>{
    if( item.img !== "" ) mode = 2;
    return true;
  })
  let numCases = caseData.cases.length;

  const selectCase = (caseid:string, cardid:string, outputs:[], pt:number, wt:number)  => {
    onCaseSelect(cardid);


    let obj = {cardid:props.card.id, select:caseid, outputs: outputs, pt:pt===undefined?1:pt, wt:wt===undefined?1.0:wt};
    if( caseData.actions[0][1].type !== 6) // remove Examinee Card
      cardLink().history.unshift(obj);

    const d = new Date();
    let now = d.getTime();

    let exID = localStorage.getItem('exID');
    if( exID !== null && exID.length === 47 && caseData.cases.length === 4){
      // PHQ, GAD, 
      let sendData = JSON.stringify({return:true, message:"cnt answers", data:{ uid: exID, game:101, aid: card.subtitle, cid: card.id, sid: caseid, update: now}});
      localStorage.setItem(`${101}`,sendData);
      
      let topic = '/mbisq/data';
      if( card.subtitle.indexOf('ARS_') !== -1)
        topic = '/mbisq/tdata';
      connectorMQTT().client.publish(
        topic, 
        sendData, 
        { qos: 2, retain: true },
        (err:any) => { if(err) alert("서버와 연결이 끊어졌습니다. 다시 시작하세요.");}
      );  

      //if(connectorMQTT().client2 !== null) 
      connectorMQTT().client2.publish(
        topic, 
        sendData, 
        { qos: 2, retain: true },
        (err:any) => { if(err) alert("서버와 연결이 끊어졌습니다. 다시 시작하세요.");}
      );  
    }
    if( caseData.actions[0][0].indexOf('[End of Answers]') !== -1 ){
      localStorage.setItem(`${101}`,'1');
    }
    //alert(cardLink().history.length+":"+cardLink().history[cardLink().history.length-1].cardid+":"+cardLink().history[cardLink().history.length-1].select);
  }

  const cwidth = cardView().width;
  const cheight = cardView().height;
  const imgWidth = ((cwidth- (numCases > 2 ? 60: 5)) / 2)|0;

  let viewmode = 1;
  if( caseData.actions !== undefined && caseData.actions[0].length === 4)
    viewmode = caseData.actions[0][2];  

  let switchDir = 0; //"column";
  let switchAlign = "center";  

  let vert = false;
  if( viewmode === 1){// normal
  }
  else if( viewmode === 2){// image
    switchDir = 1; //"row";
    switchAlign = "flex-end";
  }
  else if( viewmode === 3){// select
    switchDir = 1; //"row";
    switchAlign = "space-evenly";
  }
  else if( viewmode === 4 && cwidth > cheight ){// select
    switchDir = 0; //"row";
    switchAlign = "space-evenly";
    vert = true;
  }
  //console.log('card id compare : '+card.id +"="+ cardLink().cardid);
  const srv = ""; //"https://liflo.io"
  let bgimage = caseData.actions[0][3] === "" ? srv+"/images/white.png" : `${caseData.actions[0][3].replace(/fileServer/i, srv)}`;

  function getTitle(){
    let title = card.title;
    let found = title.match(/\[;([^}]+)\]+/g);
    if( found === null)
      return  <div  style={{whiteSpace: 'pre-line'}}>{title}</div>;
    else{
      let start = found[0].length;
      let title1 = title.substring(2,start-1);
      let title2 = title.substring(start+1);
      //console.log(title1+'/'+title2);
      return <div style={{display:'flex',  flexDirection: 'column', alignItems: 'center', width: '100%'}}>
      <div className="row hr-2">{title1}</div><br/>
      <div className="row hr-21" style={{whiteSpace: 'pre-line'}}>{title2}</div>
      </div>
    }
  }

  return (
    <CardContent sx={viewmode === 1 ? {position: 'absolute',
    zIndex: 2,
    width: cwidth-13,
    height: cheight-35,
    top: 0,
    padding: '5px',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  }: {position: 'absolute',
  zIndex: 2,
  width: cwidth-13,
  height: cheight-5,
  top: 0,
  padding: '5px',
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  backgroundColor: vert?'#fff':'#fff0',
  alignItems: (viewmode === 2)?'flex-end':'flex-start',
}}>
      <CardContent sx={{bottom: '0px'}} >
      <div className="row hr-1" >
        <div>{card.subtitle}</div>
      </div>
      </CardContent>
      <CardContent sx={{bottom: '0px', height:(viewmode === 4)?cheight/2+'px':'auto'}} >
      <div className={vert?"row hr-32":"row hr-22"}>
        {getTitle()}
      </div>
      </CardContent>

      {(card.id === cardLink().cardid || localStorage.getItem('type') === 'card') && <CardContent sx={{bottom: 0, height: 'auto', width: cwidth-43}} >
          { mode === 2 ? (
            <ImageList cols={ numCases > 2 ? 2 : 1} sx={{alignItems:'center', justifyItems: 'center'}}>
            {caseData.cases.map((item:any) => 
                <ImageListItem key={item.title} sx={{width:(numCases > 2) ?'95%':'60%'}}
                onClick={() => selectCase(item.id, item.card, item.outputs, item.pt, item.wt)}
              >
                  { item.img !== "" && (
                         <div><img
                         src={`${srv+"/images/" + item.img}?w=400&h=400&fit=crop&auto=format`}
                         srcSet={`${srv+"/images/" + item.img}?w=400&h=400&fit=crop&auto=format&dpr=2 2x`}
                         alt={item.title}
                         style={{ width: imgWidth+'px'}}
                         loading="lazy" /><ImageListItemBar position="below" title={""} /></div>
                  )}
                  </ImageListItem>
            )}
          </ImageList>
          ) : (
            <Grid container rowSpacing={2} columnSpacing={{ xs: 0, sm: 0, md: 0 }} width={cwidth-43}>
            {vert&&
            <Grid item xs={7}>
              <div style={{display:'flex',  flexDirection: 'column', alignItems: 'center'}}>
              <CardMedia component="img" image={bgimage} alt="liflo" sx={{ height: cheight*0.8, width: imgWidth}}/></div>
            </Grid>}
            <Grid item xs={vert?5:12} sx={{display:'flex',  flexDirection: 'column', justifyContent: 'center'}}>           
            <SelectList cases={caseData.cases} selectCase={selectCase} switchDir={switchDir} switchAlign={switchAlign} vert={vert}/>
            </Grid>
            </Grid>
          )}
      </CardContent>}
       

    </CardContent>
  );
};
// variant="contained"
export default SwitchCard;
/**
 * 
 <Stack direction={switchDir===0?'column':'row'} justifyContent={switchAlign}  spacing={{ xs: 4, sm: 2, md: 1 }}>
 {caseData.cases.map((item:any) => {
              function getIcon(){ 
                // SentimentDissatisfiedOutlined / SentimentNeutralOutlined
                return <SentimentSatisfiedAlt viewBox="0 0 10 10" sx={{overflow:'visible', display: 'inherit',
              marginRight: '30px', marginTop: '-24px'}}/>;}
              return  <Button key={item.title} variant="outlined" startIcon={getIcon()} color="success"  onClick={() => selectCase(item.id, item.card, item.outputs, item.pt, item.wt)} >
              {item.title}
              </Button>;
            }
            )
}
            </Stack>

      <div className="row row-start meta-section">
       <div>{card.data}</div>
      </div>

      *     <Card sx={{ maxWidth: "300", minHeight: "650", borderRadius: 3 }} variant="outlined">
 *       <svg
        className="line-svg"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 172.03 0.8"
      >
        <path
          d="M341.49,225h172"
          transform="translate(-341.49 -224.5)"
          fill="none"
          stroke="#000000"
          strokeMiterlimit="10"
        />
      </svg>

 */