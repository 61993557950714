import React, {useEffect, useRef, useState} from "react";

import {
    useMutation,
    useQuery,
    useLazyQuery,
    gql,
} from "@apollo/client";


import "./style.css";
import StackFlow from "./StackFlow";
import DeckFlow from "./DeckFlow"
import CarouselFlow from "./CarouselFlow";
import { connectorMQTT } from "../../hoc/ApolloProvider";
import data from "./data/data";
import { useFetchFlow } from "../../hook/useApiData";

interface FlowProps {
  flow: {
    id?: string;
    type?:string;
    meta?: Array<{ title: string; subtitle: string }>;
  };
  goCard:string;
}


const StaticFlow = (props: FlowProps) => {
    const { flow, goCard } = props;

    const [flowdata, setFlow] = useState<any[]>([]);

    useEffect(() => {

      const getFlow = async () => {
        const resp = await fetch('https://m2.liflo.io/mbisqid/'+flow.id+'/0.0.1');
        const postsResp = await resp.json();

        setFlow(postsResp);
      };
  
      getFlow();      
    }, []);

    if (flowdata.length === 0 ) return <p>Error!: Wrong Flow ID</p>;
    //console.log('flowdata.length:'+flowdata.length)
    const flowJson = flowdata[0];

    if (flowJson.data === undefined || flowJson.data.getFlow === null || flowJson.data.getFlow.flow === null ) return <p>Error!: Wrong Flow ID</p>;
    const cards = flowJson.data.getFlow.flow.cards;
    //const cards = data[1].getFlow?.flow.cards;

    connectorMQTT().fid = flowJson.data.getFlow.id as string;

    if (flow.type === "slide")
        return <CarouselFlow flow={{cards:cards}}/>
    else if (flow.type === "history")
        return <DeckFlow flow={{cards:cards}}/>
    else if (flow.type === "exam")// flow type
        return <StackFlow flow={{cards:cards}} goCard={goCard}/>
    else // flow type
        return <StackFlow flow={{cards:cards}} goCard={goCard}/>
}

export default StaticFlow;
