import React, {useEffect, useRef, useState} from "react";

import {
    useMutation,
    useQuery,
    useLazyQuery,
    gql,
} from "@apollo/client";


import "./style.css";
import StackFlow from "./StackFlow";
import DeckFlow from "./DeckFlow"
import CarouselFlow from "./CarouselFlow";
import { connectorMQTT } from "../../hoc/ApolloProvider";

interface FlowProps {
    flow: {
      id?: string;
      type?:string;
      meta?: Array<{ title: string; subtitle: string }>;
    };
    goCard:string;
  }

// --------------------------------------------------- 
const getFlowGQL = gql`
query getFlow($id: ID!){ getFlow(id:$id){
    id
    flow{
    cards{
        id
        title
        subtitle
        category
        data
        linkto
        img
    }
    }
    success
    error
}
}
`;

const Flow = (props: FlowProps) => {
    const { flow,goCard } = props;

    const { loading, error, data, refetch, networkStatus } = useQuery(
        getFlowGQL,
        {
          variables: { "id": flow.id },
          notifyOnNetworkStatusChange: true,
          fetchPolicy: 'network-only'
          // pollInterval: 500
        },      
    );
    
    if (networkStatus === 4) return <p>Refetching!</p>;
    if (loading) return <p>loading</p>;
    if (error) return <p>Error!: {error}</p>;
    if (data.getFlow.flow === null ) return <p>Error!: Wrong Flow ID</p>;    
    
    const cards = data.getFlow.flow.cards;
    connectorMQTT().fid = data.getFlow.id;
    if( flow.type === 'flowfull'){
        const doc = document.documentElement as any;
    
        //console.log(typeof doc.requestFullscreen)
        if (typeof doc.requestFullscreen === "function") {
          doc.requestFullscreen()
        }
      
        //console.log(typeof doc.webkitRequestFullscreen)
        if (typeof doc.webkitRequestFullscreen === "function") {
          doc.webkitRequestFullscreen()
        }    
      } 

    if (flow.type === "slide")
        return <CarouselFlow flow={{cards:cards}}/>
    else if (flow.type === "history")
        return <DeckFlow flow={{cards:cards}}/>
    else if (flow.type === "exam")// flow type
        return <StackFlow flow={{cards:cards}} goCard={goCard}/>
    else // flow type
        return <StackFlow flow={{cards:cards}} goCard={goCard}/>
}

export default Flow;
