import React from "react";
import { cardLink, cardView, GET_DARK_MODE, userGQL, UserQuery} from "../../hoc/ApolloProvider";

import { useQuery, useReactiveVar } from '@apollo/client'
import { userDatas } from "../../hoc/cache";

const Editor = () => {


  const userData = useReactiveVar(userDatas); // refresh UI by value
/*
  const { error, data, loading } = useQuery<UserQuery>(userGQL, {
    fetchPolicy: 'cache-and-network',
  });
  if (!data) {
    // we don't have data yet

    if (loading) {
      // but we're loading some
      return <h2>Loading initial data...</h2>;
    }
    if (error) {
      // and we have an error
      return <h2>Error loading data :(</h2>;
    }
    return <h2>Unknown error :(</h2>;
  }
*/
  const doEditor = () =>  {
    let host = 'https://fe.liflo.io';
    if( cardLink().dev ) host = 'http://localhost:3000';
    const url = host + '/card/0/'+cardLink().editcardid;
    window.open(url, '_blank', "height=800,width=1100");
  }
  if( cardLink().editcardid !== '' && false)
    return (
      <div className="editor" onClick={doEditor}>
          <div>
            edit
          </div>
      </div>
    );
  else
    return <></>;
};

export default Editor;
//{cardView().width} x {cardView().height} : {window.innerWidth} x {window.innerHeight}
// {cardView().width} x {cardView().height} : {window.innerWidth} x {window.innerHeight}
//userData.cid;        