
import {
    useState, useMemo, useEffect, useCallback,
  } from 'react';
import {isMobile} from 'react-device-detect';  
import { cardView } from '../hoc/ApolloProvider';
import { userDatas } from '../hoc/cache';
  
    type ScreenSizeType = {
      height: number;
      width: number;
    }
  
  const useResize = (): ScreenSizeType => {
    const [height, setHeight] = useState(cardView().height);
    const [width, setWidth] = useState(cardView().width);
  
    const size = useMemo(() => ({ height, width }), [height, width]);
    const memoResizeHandler = useCallback(resizeHandler, []);
  
    useEffect(() => {
      //console.log('add resize hnadler **************');
        window.addEventListener('load', memoResizeHandler)
        window.addEventListener('resize', memoResizeHandler);
        return () => {
          //console.log('remove resize hnadler **************');
          window.removeEventListener('load', memoResizeHandler)
            window.removeEventListener('resize', memoResizeHandler);
        };
    }, [memoResizeHandler]);
  
    function resizeHandler() {
      if(userDatas().reSize === false) return;
      //console.log('call resizeHandler in ************** ('+width+'x'+height+')');
      //console.log("WINDOW 2(wxh): "+window.innerWidth+" x "+window.innerHeight);
      let hoffset = 0;
      let voffset = 0;
  
      //let isMobile = (typeof window.orientation !== 'undefined') || (navigator.userAgent.indexOf('IEMobile') !== -1);
      if( isMobile !== true ){
        hoffset = 0;
        voffset = 0;
      }
      else{
        //console.log("mobile device");
        hoffset = 0;
        voffset = 0;
      }
      //after 221014----------------------------------------
      let mode = 1; // width 기준
      let ratio = 1.56; //1.618 golden ratio card 1.603/1.56, A4 1.414 / 0.64

      if(window.innerWidth > window.innerHeight)
        ratio = (window.innerHeight / window.innerWidth)-0.05;

      let cWidth = window.innerWidth - window.innerWidth/10;
      let cHeight = (window.innerWidth - hoffset)*ratio+voffset; //+100; fullscreen compenation

      if( localStorage.getItem('type') !== 'exam' ){ // height 기준
        cHeight = window.innerHeight - 70;
        cWidth = (cHeight - voffset)/ratio + hoffset - 60;
        if( cWidth+30 > window.innerWidth) cWidth = window.innerWidth - 60;
        mode = 2;
      }

      cHeight = (cHeight - 0)|0;
      cWidth = (cWidth + 0)|0;  

      ratio = (cHeight*100 / cWidth)|0;
      /*
      if( isMobile && userDatas().ratio*1.5 < ratio){
        console.log('ratio change:'+isMobile+':'+ userDatas().ratio + '->' + ratio);
        return;
      }
      */
      setHeight(cHeight);
      setWidth(cWidth);
      userDatas({...userDatas(), width:cWidth, height:cHeight, ratio: ratio});
      cardView({ mobile:isMobile, width:cWidth, height:cHeight});
      //console.log('call resizeHandler out ************** ('+cWidth+'x'+cHeight+')');
    }
    
    return size;
  };
  
  export default useResize;
/**

        window.addEventListener('load', handleResize)
        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('load', handleResize)
            window.removeEventListener('resize', handleResize)
        }

 */  
