import { MoodBadOutlined, SentimentDissatisfiedOutlined, SentimentNeutralOutlined, SentimentSatisfiedAlt } from "@mui/icons-material";
import { Button, Stack } from "@mui/material";
import styled from "styled-components";

const Title = styled.h1`
  text-align: center;
  font-family: "NanumC", "Roboto", sans-serif;
  font-weight: bold;
  color: #000;
  margin-bottom: 0px;
  margin-top: 10px;
`;


const SelectList = ({cases,selectCase,switchAlign,switchDir, vert}:{cases:[], selectCase:any, switchAlign:string, switchDir:number, vert:boolean}) => {

    //const { datas:[], selectCase, switchAlign, switchDir} = props;

    function getIcon(idx:number){ 
        // SentimentDissatisfiedOutlined / SentimentNeutralOutlined
        if( idx === 0 )
            return <SentimentSatisfiedAlt viewBox="0 0 10 10" sx={{overflow:'visible', display: 'inherit', marginRight: '30px', marginTop: '-24px'}}/>;
        else if( idx === 1 )
            return <SentimentNeutralOutlined viewBox="0 0 10 10" sx={{overflow:'visible', display: 'inherit', marginRight: '30px', marginTop: '-24px'}}/>;
        else if( idx === 2 ) 
            return <MoodBadOutlined viewBox="0 0 10 10" sx={{overflow:'visible', display: 'inherit', marginRight: '30px', marginTop: '-24px'}}/>;
        else if( idx === 3 ) 
            return <SentimentDissatisfiedOutlined viewBox="0 0 10 10" sx={{overflow:'visible', display: 'inherit', marginRight: '30px', marginTop: '-24px'}}/>;  
        else
            return <></>;      
    }

    let bspace = {xs: 1, sm: 1, md: 1};
    if( vert )
        bspace = {xs: 5, sm: 3, md: 2};

    return  (<Stack direction={switchDir===0?'column':'row'} justifyContent={switchAlign}  spacing={bspace} sx={{paddingTop:'0%'}}>
        {
        cases.map((item:any,idx) => {
        let title = item.title;
        let nPos = item.title.search(/\[;[0-9]\]+/g);    
        let nIcon = 9;
        if( nPos !== -1 ){
            nIcon = parseInt(item.title[nPos+2],10);
            title =  item.title.substring(4);
        }

        let nRPos = item.title.search(/\[r;[1-9][0-9]\]+/g);    
        let nRule = 0;
        if( nRPos !== -1 ){
            nRule = parseInt(item.title.substring(nRPos+3,nRPos+5),10);
            if( nRule === 10 && localStorage.getItem(`${1}`) === '1') nRule = -1;
            if( nRule === 11 && localStorage.getItem(`${2}`) === '1') nRule = -1;
            if( nRule === 12 && localStorage.getItem(`${3}`) === '1') nRule = -1;
            if( nRule === 13 && localStorage.getItem(`${4}`) === '1') nRule = -1;
            if( nRule === 14 && localStorage.getItem(`${5}`) === '1') nRule = -1;
            if( nRule === 15 && localStorage.getItem(`${51}`) === '1') nRule = -1;
            if( nRule === 16 && localStorage.getItem(`${52}`) === '1') nRule = -1;
            if( nRule === 17 && localStorage.getItem(`${53}`) === '1') nRule = -1;
            if( nRule === 18 && localStorage.getItem(`${54}`) === '1') nRule = -1;
            if( nRule === 19 && localStorage.getItem(`${55}`) === '1') nRule = -1;            
            if( nRule === 20 && localStorage.getItem(`${101}`) === '1') nRule = -1;            
            title =  item.title.substring(6) + (nRule === -1?' - 완료 했습니다.':'');
        }
        return  <Button key={item.title} variant={(nIcon===9)?'contained':'outlined'}
        startIcon={getIcon(nIcon)} color={(nIcon===9)?'primary':'primary'} disabled={(nRule ===-1)?true:false}
        sx={{justifyContent:((nIcon===9)?'center':'flex-start'), backgroundColor:(nIcon===9)?'':'#ffff', height:'55px',
        "&:hover": {
            background: "#0004", // <- add here your desired color, for demonstration purposes I chose red
          }}}
        onClick={() => nRule === -1?{}:selectCase(item.id, item.card, item.outputs, item.pt, item.wt)}>
        {nIcon !== 9 && <Title style={{fontSize: vert?'35px':'30px'}}>{title}</Title>}
        {nIcon === 9 && title}
        </Button>;
        })           
        }
    </Stack>);
};

/*
{datas.map((item:any) => {
    return  <Button key={item.title} variant="outlined" 
    startIcon={getIcon()} color="success"  
    onClick={() => selectCase(item.id, item.card, item.outputs, item.pt, item.wt)}>
    {item.title}
    </Button>;
    })}
    */

export default SelectList;