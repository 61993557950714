import React, { useEffect, useState } from "react";
import { cardLink, cardView, GET_DARK_MODE, userGQL, UserQuery} from "../../hoc/ApolloProvider";

import { useQuery, useReactiveVar } from '@apollo/client'
import { userDatas } from "../../hoc/cache";

const DebugInfo = ({onGoCard}:any) => {


  const userData = useReactiveVar(userDatas); // refresh UI by value
/*
  const { error, data, loading } = useQuery<UserQuery>(userGQL, {
    fetchPolicy: 'cache-and-network',
  });
  if (!data) {
    // we don't have data yet

    if (loading) {
      // but we're loading some
      return <h2>Loading initial data...</h2>;
    }
    if (error) {
      // and we have an error
      return <h2>Error loading data :(</h2>;
    }
    return <h2>Unknown error :(</h2>;
  }
*/
  const { data:{darkMode}} = useQuery(GET_DARK_MODE)
  let userid = userData.uid; 
  let username = userData.uname;
  if(userData.code.length !== 5)
    username = 'mbisq';
  let userPath = "/id/"+userData.type.toString(); //"https://fd.liflo.io/flow/"+userid; 
  //if( userid === '')
  //  userPath = "https://fd.liflo.io/flow/633b8703477e97032251b812";
  let exID = localStorage.getItem('exID') as string;
  const [toggle, setToggle] = useState(true);
  const onClick = () =>{
    if( userData.code.length === 5 && cardLink().goCards.length !== 0 && cardLink().goCards['1'] !== undefined && cardLink().goCards['1'].length === 24){
      //console.log('cardLink().goCards:'+cardLink().goCards['1']);
      onGoCard(toggle?cardLink().goCards['1']:''); //'636a15cbf6be96031b4653cf');
      setToggle(!toggle);
    }
  }
  return (

      <div className="debug" onClick={onClick}>
        <div>
          {userid !== ""?username:'mbisq'} 
        </div>
      </div>
  );
};

export default DebugInfo;
// {userData.width} x {userData.height} : {userData.ratio}
//{cardView().width} x {cardView().height} : {window.innerWidth} x {window.innerHeight}
// {cardView().width} x {cardView().height} : {window.innerWidth} x {window.innerHeight}
//userData.cid;        : {exID} 
/**
 * 
 *     <a
      href={userPath}
      target="_blank"
      rel="noreferrer"
      className="debug-container"
    >
    </a>
 */