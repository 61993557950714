import React, {useState, useEffect} from "react";
import "./style.css";

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import {Typography, Box, CardContent, Divider, Chip, Grid} from '@mui/material'
import { cardLink, cardView } from "../../hoc/ApolloProvider";
import {
  useMutation,
  gql,
} from "@apollo/client";
import Assessment from "../Form/Assessment";
import Profile from "../Form/Profile";




const getFlowGQL = gql`
query{
  getFlow(
    id : "62bc2a2bbec7bf7236122c6e"
  ){
    id
    flow{
      cards{
        id
        title
        category
        data
      }
    }
    success
    error
  }
}
`;

interface CardProps {
  card: {
    id:string;
    title: string; 
    subtitle: string;
    data: string;
    category: string;
    linkto: string;
    img: string;
  };
  onCaseSelect: any;
}

const MessageCard = (props: CardProps) => {
  const { card, onCaseSelect } = props;

  if( card.data === null ) return<></>; 

  let cardData = (card.data ==='')?{cases:[],
    actions:[[card.subtitle, {type:0,action:["",""],rules:""}, 2, ""]],
    content:{}}:JSON.parse(card.data);

  //console.log( "cardData:"+card.data);

  const reload = () =>  {
    window.location.reload();
  }

  const cwidth = cardView().width;
  const cheight = cardView().height;

  if( card.id === cardLink().cardid && cardData.actions[0][1].type === 1){
    let getPoint = () => {
      let point = 0;
      let results = [];
      for( let i = 0 ; i < cardLink().outputs.length; i++){
        results[cardLink().outputs[i]] = 0;
      }
      for( let i = 0 ; i < cardLink().history.length; i++){
        point = point + cardLink().history[i].pt*cardLink().history[i].wt;
        for( let j = 0; cardLink().history[i].outputs !== undefined && j < cardLink().history[i].outputs.length; j++ ){
          if( !isNaN(results[cardLink().history[i].outputs[j].output]))
            results[cardLink().history[i].outputs[j].output] = results[cardLink().history[i].outputs[j].output] +  cardLink().history[i].outputs[j].pt*cardLink().history[i].outputs[j].wt;
        }
      }
      //console.log(results);

      let outs = [];
      for( let i = 0 ; i < cardLink().outputs.length; i++){
        outs.push({output:cardLink().outputs[i],point:results[cardLink().outputs[i]]});
      }
      //let data = JSON.stringify(cardLink().history);
      //alert( data);  
      //const report = Object.entries(results).map(([k,v]) => `${k} ${v}점  `);
      return outs;
    }

    cardData = {
      cases:[],
      actions:[[card.subtitle, {type:1,action:["",""],rules:"",cat:6}, 1, ""]],
      content:{
        flowcardid:'',
        history: cardLink().history,
        assessment:getPoint(),
        examinee:{name:localStorage.getItem('exName'), bdate: localStorage.getItem('exBdate')}
      }
    };
  }

  if( localStorage.getItem('type') === 'slide' && cardData.content !== undefined && cardData.content.history !== undefined){
    let selected:any = [];
    cardData.content.history.map((v:any,i:any)=>{
      selected[v.cardid] = v.select;
    })
    cardLink().selected = selected;
  }

  return (
    <CardContent sx={{position: 'absolute',
    zIndex: 2,
    width: cwidth-33,
    height: cheight-40,
    top: 0,
    padding: '5px',
    }}>
      <Stack direction="column" justifyContent="center" alignItems="center" spacing={{ xs: 2, sm: 1, md: 1 }} sx={{height:cheight-40}}>
   
        <Stack direction="column" justifyContent="center" alignItems="center" spacing={{ xs: 2, sm: 2, md: 7 }}>
        <Typography variant="h4" component="h5" display="block" style={{whiteSpace: 'pre-line'}}>
        {card.title}
        </Typography>
        {(cardData.actions[0][1].cat === 6 && cardData.actions[0][1].type === 1) && <Assessment data={cardData}/>}
        {(cardData.actions[0][1].cat === 6 && cardData.actions[0][1].type === 2) && <Profile data={cardData.content}/>}
        { ( cardData.actions[0][1].cat === 2 || cardData.actions[0][1].type === 0) &&
          <>
            <Typography variant="h5" component="h5" className="row hr-1">
            {card.subtitle}
            </Typography>
            <Button variant="contained" color="success" onClick={() => reload()} >
                  다 시 하 기
            </Button>
          </>}
        </Stack>
      </Stack>
    </CardContent>
  );
};

export default MessageCard;

/**
        {card.id === cardLink().cardid && <>
        { cardData.actions[0][1].type !== 1 &&
          <>
            <Box component="div" sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
              <Box component="div" sx={{ my: 3, mx: 2 }}>
                <Grid container alignItems="center">
                  <Grid item xs>
                    <Typography gutterBottom variant="h5" component="div">
                    {localStorage.getItem('name')}의 검사 결과입니다.
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Box component="div" sx={{ my: 3, mx: 2 }}>
                <Grid container alignItems="center">
                  <Grid item xs>
                    <Typography gutterBottom variant="h6" component="div">
                    {card.subtitle}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography gutterBottom variant="h6" component="div">
                      양호
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Divider variant="middle" />
              <Box component="div" sx={{ my: 3, mx: 2 }}>
                {outs.map((v,i)=>(
                  <Typography color="text.secondary" key={i} variant="body1">{v.output}:{v.point}</Typography>
                )) }
              </Box>
              <Divider variant="middle" />
              <Box component="div" sx={{ m: 2 }}>
                <Typography gutterBottom variant="body2">
                  keywords
                </Typography>
                <Stack direction="row" spacing={1}>
                  <Chip label="우울" />
                  <Chip color="primary" label="ADHD" />
                  <Chip label="발달" />
                  <Chip label="치료" />
                </Stack>
              </Box>
            </Box>
            <GenerateHistory outs={outs}/>
            <Button variant="contained" color="success" onClick={() => reload()} >
                  다 시 하 기
            </Button>
          </>}

 */