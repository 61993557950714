import { gql, useMutation } from "@apollo/client";
import { Box, Button, Chip, Divider, Grid, Stack, Typography } from "@mui/material";
import { useEffect } from "react";

export const generateHistoryGQL = gql`
mutation generateHistory( $id: ID! $input:CardUpdate!){
  generateHistory( id:$id, input: $input) {
    success
    error
    id
    data
  }
}
`;

function GenerateHistory(props:any) {

  const [generateHistory, { loading, error, data }] = useMutation(generateHistoryGQL, 
    {      
      onCompleted: (data) => {
        const url = 'https://fd.liflo.io/slide/'+data.generateHistory.id;
        window.open(url, '_blank', "height=800,width=550");    
      }
    },
  );

  useEffect(()=>{
  },[]);

  if (loading) return <p>generate History ...</p>;
  if (error) return <p>Error!: {error.message}</p>;
  //console.log(data);




  function openFlow() {
    //const url = 'https://liflo.io/link/'+cardLink().flowid+'/'+cardLink().cardid;

    const dataJson = {
       cases:[],
       actions:[["",{type:1,action:[],rules:[]},6,""]], // cat:6(form) - type:1(assessment result)
       content:props.outs.content, 
    }

    //{\"cases\":[{\"id\":\"Connect_1\",\"title\":\"다음\",\"flow\":\"63018ded033fa30319e2d9af\",\"card\":\"63107c0d0d96270310ff8489\",\"img\":\"\",\"pt\":1,\"wt\":1}],\"actions\":[[\"\",{\"type\":3,\"action\":[\"\",\"\"],\"rules\":\"\"},1,\"\"]]}
    //json.flowcardid = cardLink().flowcardid;
    const jsonString = JSON.stringify(dataJson, null, "\t");
    //console.log("history data:"+jsonString);


    generateHistory({
                  variables: { 
                    "id": '', 
                    "input": {
                            //"title": "",
                            //"category": "assess",
                            "owner": localStorage.getItem('exID'),
                            "linkto": "", // existing history flow id
                            "data": jsonString}
                    }
                });
  }

  return(
    <Button variant="contained" color="success" onClick={() => openFlow()} >
    결과 카드 보기
    </Button>
);
}

export default function Assessment(props:any) {

    return(
        <Box component="div" sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
              <Box component="div" sx={{ my: 3, mx: 2 }}>
                <Grid container alignItems="center">
                  <Grid item xs>
                    <Typography gutterBottom variant="h5" component="div">
                    {props.data.content.examinee.name}의 검사 결과입니다.
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Box component="div" sx={{ my: 3, mx: 2 }}>
                <Grid container alignItems="center">
                  <Grid item xs>
                    <Typography gutterBottom variant="h6" component="div">
                    {props.data.actions[0][0]}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography gutterBottom variant="h6" component="div">
                      양호
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Divider variant="middle" />
              <Box component="div" sx={{ my: 3, mx: 2 }}>
                {props.data.content.assessment.map((v:any,i:number)=>(
                  <Typography color="text.secondary" key={i} variant="body1">{v.output}:{v.point}</Typography>
                )) }
              </Box>
              <Divider variant="middle" />
              <Box component="div" sx={{ m: 2 }}>
                <Typography gutterBottom variant="body2">
                  keywords
                </Typography>
                <Stack direction="row" spacing={1}>
                  <Chip label="우울" />
                  <Chip color="primary" label="ADHD" />
                  <Chip label="발달" />
                  <Chip label="치료" />
                </Stack>
              </Box>
              {localStorage.getItem('type') !== 'slide' && <Box component="div" sx={{ m: 2 }}>
              <GenerateHistory outs={props.data}/>
              </Box>}
        </Box>
    )
}