import React, {useEffect, useState} from "react";
import "./style.css";

import Card from '@mui/material/Card';
import { makeStyles} from "@mui/styles";

import SwitchCard from "./SwitchCard";
import LinkCard from "./LinkCard";
import MessageCard from "./MessageCard";
import ActionCard from "./ActionCard"

import { CardMedia } from "@mui/material";
import SwitchResultCard from "./SwitchResultCard";
import EditorCard from "./EditorCard";

const useStyles = makeStyles(() => ({
  card: {
    borderRadius: 3,
    boxShadow: 'none',
    position: 'relative',
    minWidth: 200,
    minHeight: 360,
    '&:after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      width: '100%',
      height: '100%',
      bottom: 0,
      zIndex: 1,
      //background: 'linear-gradient(180deg, rgba(0,0,0,0), grey)',
    },
  },
  content: {
    position: 'absolute',
    zIndex: 2,
    top: 0,
    //width: '100%',
  },
  media:{
    backgroundPosition: 'top',
  }
}));

interface CardProps {
  card: {
    id: string;
    title: string; 
    subtitle: string;
    data: string;
    category: string;
    linkto: string;
    img: string;
  };
  width: number;
  height: number;
  onCaseSelect?: any;
}

// https://liflo.io/images/template.png : black
// https://liflo.io/images/flow.png : white
// https://image-us.samsung.com/SamsungUS/home/audio/galaxy-buds/MB-04-JustWhatYouWantV4.jpg
const BaseCard = (props: CardProps) => {
  const { card, onCaseSelect, width, height } = props;

  //const mediaStyles = useCoverCardMediaStyles({ bgPosition: 'top' })
  const classes = useStyles();
  const type = localStorage.getItem('type');
  //console.log( "cardData:"+card.data);

  useEffect(()=>{
  },[]);

  if (card === null ) return <p>Error!: Wrong Card ID</p>;

  const linkTo = (link:string) =>  {
    window.open(link, '_blank', "height=800,width=550");
  }

  const onSwitch = (cardId: any) => {
    onCaseSelect( cardId);
  }

  // flow.png -> white.png
  const srv = ""; //"https://liflo.io"

  let bgimage = card.img === "" ? srv+"/images/white.png" : `${card.img.replace(/fileServer/i, srv)}`;

  return (
    <Card sx={{ borderRadius: 3,
      boxShadow: 'none',
      position: 'relative',
      minWidth: 200,
      minHeight: 360,
      '&:after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        width: '100%',
        height: '100%',
        bottom: 0,
        zIndex: 1,
        //background: 'linear-gradient(180deg, rgba(0,0,0,0), grey)',
      }, }} variant="outlined">
      <CardMedia
        component="img"
        height={height}
        image={bgimage}
        alt="liflo"
      />

      { (card.category === "switch" && type !== 'slide') && <SwitchCard card={card} onCaseSelect={(idx:any) => onSwitch(idx)}/>}
      { (card.category === "switch" && type === 'slide') && <SwitchResultCard card={card} onCaseSelect={(idx:any) => onSwitch(idx)}/>}
      { (card.category === "action" && type !== 'slide') && <ActionCard card={card} onCaseSelect={(idx:any) => onSwitch(idx)}/>}
      { (card.category === "action" && type === 'slide') && <ActionCard card={card} onCaseSelect={(idx:any) => onSwitch(idx)}/>}
      { card.category === "link" && <LinkCard card={card} onCaseSelect={(idx:any) => onSwitch(idx)}/>}
      { card.category === "message" && <MessageCard card={card} onCaseSelect={(idx:any) => onSwitch(idx)}/>}
      { card.category === "assess" && <EditorCard card={card} onCaseSelect={(idx:any) => onSwitch(idx)}/>}
    </Card>
  );
};

export default BaseCard;

/**
 * 
 */
