import React, {useState} from "react";
import "./style.css";

import CardContent from '@mui/material/CardContent';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from "@mui/material/ImageListItemBar";

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import { cardLink, cardView } from "../../hoc/ApolloProvider";

interface CardProps {
  card: {
    id: string;
    title: string; 
    subtitle: string;
    data: string;
    category: string;
  };
  onCaseSelect: any;
}

const SwitchResultCard = (props: CardProps) => {
  const { card, onCaseSelect } = props;

  let mode = 1; // 1 image 

  if( card.data === null || card.data === "") return(<></>);

  //console.log( "cardData:"+card.data);
  const caseData = JSON.parse(card.data);
  
  caseData.cases.map((item:any) =>{
    if( item.img !== "" ) mode = 2;
    return true;
  })
  let numCases = caseData.cases.length;

  const selectCase = (caseid:string, cardid:string, outputs:[], pt:number, wt:number)  => {
  }

  const cwidth = cardView().width;
  const cheight = cardView().height;
  let viewmode = 1;
  if( caseData.actions !== undefined && caseData.actions[0].length === 3)
    viewmode = caseData.actions[0][2];  

  let switchDir = 0; //"column";
  let switchAlign = "center";  


  if( viewmode === 1){// normal
  }
  else if( viewmode === 2){// image
    switchDir = 1; //"row";
    switchAlign = "flex-end";
  }
  else if( viewmode === 3){// select
    switchDir = 1; //"row";
    switchAlign = "space-evenly";
  }
  //console.log('card id compare : '+card.id +"="+ cardLink().cardid);
  const srv = ""; //"https://liflo.io"
  
  return (
    <CardContent sx={viewmode === 1 ? {position: 'absolute',
    zIndex: 2,
    width: cwidth-13,
    height: cheight-35,
    top: 0,
    padding: '5px',
  }: {position: 'absolute',
  zIndex: 2,
  width: cwidth-13,
  height: cheight-35,
  top: 0,
  padding: '5px',
  display: 'flex',
  justifyContent: 'flex-end',
  flexDirection: 'column',
  alignItems: (viewmode === 2)?'flex-end':'flex-start',
}}>
      <CardContent sx={{bottom: 0, height: 'auto'}} >
      <div className="row hr-1" >
        <div>{card.subtitle}</div>
      </div>
      <div className="row hr-2">
        <div  style={{whiteSpace: 'pre-line'}}>{card.title}</div>
      </div>
      </CardContent>
      { viewmode === 3 && <CardContent sx={{bottom: 0, height: '100%'}} >
      <div className="row hr-1" >
      </div>
      </CardContent>}
      <CardContent sx={{bottom: 0, height: 'auto', width: cwidth-43}} >
          { mode === 2 ? (
            <ImageList cols={ numCases > 2 ? 2 : 1} sx={{alignItems:'center', justifyItems: 'center'}}>
            {caseData.cases.map((item:any) => 
                <ImageListItem key={item.title} sx={{width:(numCases > 2) ?'95%':'60%', border:5, borderColor: cardLink().selected[card.id] === item.id ? 'error.main' : 'grey.500'}}
                onClick={() => selectCase(item.id, item.card, item.outputs, item.pt, item.wt)}
              >
                  { item.img !== "" && (
                         <div><img
                         src={`${srv+"/images/" + item.img}?w=150&h=150&fit=crop&auto=format`}
                         srcSet={`${srv+"/images/" + item.img}?w=150&h=150&fit=crop&auto=format&dpr=2 2x`}
                         alt={item.title}
                         loading="lazy" /><ImageListItemBar position="below" title={""} /></div>
                  )}
                  </ImageListItem>
            )}
          </ImageList>
          ) : (
            <Stack direction={switchDir===0?'column':'row'} justifyContent={switchAlign} alignItems="stretch" spacing={{ xs: 2, sm: 1, md: 1 }}>
            {caseData.cases.map((item:any) => 
              <Button key={item.title} variant="contained" color={cardLink().selected[card.id] === item.id ? "error":"success"} onClick={() => selectCase(item.id, item.card, item.outputs, item.pt, item.wt)} >
              {item.title}
              </Button>
            )}
            </Stack>
          )}
      </CardContent>
    </CardContent>
  );
};

export default SwitchResultCard;
/**
      <div className="row row-start meta-section">
       <div>{card.data}</div>
      </div>

      *     <Card sx={{ maxWidth: "300", minHeight: "650", borderRadius: 3 }} variant="outlined">
 *       <svg
        className="line-svg"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 172.03 0.8"
      >
        <path
          d="M341.49,225h172"
          transform="translate(-341.49 -224.5)"
          fill="none"
          stroke="#000000"
          strokeMiterlimit="10"
        />
      </svg>

 */