import React, {useState} from "react";
import "./style.css";

import CardContent from '@mui/material/CardContent';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import { cardLink, cardView } from "../../hoc/ApolloProvider";
import Action from "../Action/Action";

interface CardProps {
  card: {
    id: string;
    title: string; 
    subtitle: string;
    data: string;
    category: string;
  };
  onCaseSelect: any;
}

const ActionCard = (props: CardProps) => {
  const { card, onCaseSelect } = props;
  const [status,setStatus] = useState(1); // 0: close, 1: open
  let mode = 1; // 1 image 

  if( card.data === null || card.data === "") return(<></>);

  //console.log( "cardData:"+card.data);
  const caseData = JSON.parse(card.data);
  
  caseData.cases.map((item:any) =>{
    if( item.img !== "" ) mode = 2;
    return true;
  })
  let numCases = caseData.cases.length;

  const selectCase = (caseid:string, cardid:string, outputs:[], pt:number, wt:number)  => {
    setStatus(0);
    onCaseSelect(cardid);
    let obj = {cardid:props.card.id, select:caseid, outputs: outputs, pt:pt===undefined?1:pt, wt:wt===undefined?1.0:wt};
    if( caseData.actions[0][1].type !== 6) // remove Examinee Card
      cardLink().history.unshift(obj);
    //alert(cardLink().history.length+":"+cardLink().history[cardLink().history.length-1].cardid+":"+cardLink().history[cardLink().history.length-1].select);
  }

  const nextCard = (cIdx:number = 0) => {
    if( caseData.cases.length === 0 || caseData.cases.length <= cIdx) return;
    let item = caseData.cases[cIdx];
    let caseid:string = item.id, cardid:string=item.card, outputs:[]=item.outputs, pt:number=item.pt, wt:number=item.wt;

    setStatus(0);
    onCaseSelect(cardid);
    let obj = {cardid:props.card.id, select:caseid, outputs: outputs, pt:pt===undefined?1:pt, wt:wt===undefined?1.0:wt};
    if( caseData.actions[0][1].type !== 6) // remove Examinee Card
      cardLink().history.unshift(obj);
  }

  const cwidth = cardView().width;
  const cheight = cardView().height;
  const srv = ""; //"https://liflo.io"

  return (
    <CardContent sx={{position: 'absolute',
    zIndex: 2,
    width: cwidth-13,
    height: cheight,
    top: 0,
    padding: '5px', 
    '&:last-child': { pb: 0 }
    }}>
      <CardContent sx={{bottom: 0, height: 10, zIndex:50}} >
      <div className="row hr-1">
        <div style={{zIndex:50}}>{card.subtitle}</div>
      </div>
      </CardContent>
      {(card.id === cardLink().cardid || localStorage.getItem('type') === 'card') && <>
      <CardContent sx={{ position: "absolute", zIndex:50, top: 1, right: 1, height: 'auto'}} >
          { mode === 2 ? (
            <ImageList cols={ numCases > 1 ? 2 : 1} >
            {caseData.cases.map((item:any) => 
                <ImageListItem key={item.title} 
                onClick={() => selectCase(item.id, item.card, item.outputs, item.pt, item.wt)}
              >
                  { item.img !== "" && (
                  <img
                  src={`${srv+"/images/"+item.img}?w=150&h=150&fit=crop&auto=format`}
                  srcSet={`${srv+"/images/"+item.img}?w=150&h=150&fit=crop&auto=format&dpr=2 2x`}
                  alt={item.title}
                  loading="lazy"/>
                  )}
                  </ImageListItem>
            )}
          </ImageList>
          ) : ( caseData.actions[0][1].type !== 10 &&
            <Stack direction="column" justifyContent="center" alignItems="stretch" spacing={{ xs: 2, sm: 1, md: 1 }} >
            {caseData.cases.map((item:any) => 
              <Button key={item.title} variant="contained" color="success" onClick={() => selectCase(item.id, item.card, item.outputs, item.pt, item.wt)} sx={{zIndex:50}}>
              {item.title}
              </Button>
            )}
            </Stack>
          )}

      </CardContent>
      <CardContent sx={{ height: (cheight-160)+'px',width: (cwidth-15)+'px', bottom: '0px',paddingTop: '50px',paddingRight: '1px', paddingLeft: '1px', zIndex:50}} >
        {caseData.actions[0][1] !== undefined && <Action status={status} cid={card.id} src={caseData} title={""} path={""} nextCard={(cIdx:number)=>nextCard(cIdx)}/>}
      </CardContent>
      </>}
    </CardContent>
  );
};

export default ActionCard;
/**
      <div className="row row-start meta-section">
       <div>{card.data}</div>
      </div>

      *     <Card sx={{ maxWidth: "300", minHeight: "650", borderRadius: 3 }} variant="outlined">
 *       <svg
        className="line-svg"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 172.03 0.8"
      >
        <path
          d="M341.49,225h172"
          transform="translate(-341.49 -224.5)"
          fill="none"
          stroke="#000000"
          strokeMiterlimit="10"
        />
      </svg>

 */