/**

https://liflo.io/graphql

query{
  allUsers{
    id
     username
  }
} 

query{
  getUser(
    id : "5baa268b6279ed51678e8ebd"
  ){
    success
    user{
      username
      id
      flows{
        id
        title
      }
      flowid
      
    }
    error
  }
}

mutation{
  signUp(
    username: "test27"
    password: "pass1"
  ){
    id
    success
    token
    error
  }
}


mutation{
  logIn(
    username: "blueton12@gmail.com"
    password: "eugene12"
  ){
    id
    success
    token
    error
  }
}

mutation{
  signUp(
    username: "blueton12@gmail.com"
    password: "eugene12"
  ){
    id
    success
    token
    error
  }
}

query{
  getUserByToken(
    token : "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6ImJsdWV0b24xMkBnbWFpbC5jb20iLCJfaWQiOiI2MzMwMmQ4ZWY2OWQ0YjAzMWQ2NDk1MmEiLCJpZCI6IjYzMzAyZDhlZjY5ZDRiMDMxZDY0OTUyYSIsImlhdCI6MTY2NDEwMTc3NH0.OFZ7Ziu7UIoPzhdcC3tZ5XUswfJiGTklhLYyyFxCkow"
  ){
    success
    id
    user{
      username
      id
    }
  }
}

https://github.com/Ashish1101/GrapgQlLearning/blob/de67e55326a48aafd94b0d4f6e3e9bff5d4fba20/client/src/Components/Login.jsx

 */
/*
import { gql, useMutation, useQuery } from "@apollo/client";

// --------------------------------------------------- 
export const getUserByToken = gql`
query getUserByToken( $token: String!){
  getUserByToken( token:$token){
    id
    user{
			id
      username
      flowid
      cards{
        category
        title
        subtitle
        data
        linkto
        color
        img
        id
        editable
        source
        shape
      }
      flows{
        id
        category
        title
        editable
        source
      }
    }
    success
    error
  }
}
`;

export const signUp = gql`
mutation signUp( $username:String! $password: String!){
  signUp( username: $username, password: $password) {
    success
    token
    error
    id
  }
}
`;

export const logIn = gql`
mutation logIn( $username:String! $password: String!){
  logIn( username: $username, password: $password) {
    success
    token
    error
    id
  }
}
`;

const Login = (props:any) => {

    const [doLogin, { loading, error, data }] = useMutation(logIn, {
      // pollInterval: 500
      refetchQueries: [getUserByToken, "getUserByToken"]
    });
  
    if (loading) return <p>login ...</p>;
    if (error) return <p>Error!: {error.message}</p>;
    console.log(data);

    function login(){
      
      doLogin({ variables: {   
        "username": props.id, 
        "password": props.password},
        notifyOnNetworkStatusChange: true,  
      });
    }    
}
*/
// ref : https://github.com/Ashish1101/GrapgQlLearning/blob/de67e55326a48aafd94b0d4f6e3e9bff5d4fba20/client/src/graphql/Mutaions.js
import { gql, useMutation, useQuery } from "@apollo/client";
import { Label } from "@mui/icons-material";
import { Alert, Box, Button, Chip, CircularProgress, Divider, FormGroup, Grid, Input, InputLabel, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { cardView, darkMode as toggleMode, GET_DARK_MODE } from "../../hoc/ApolloProvider";
import { userDatas } from "../../hoc/cache";

const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const logIn = gql`
mutation logIn( $username:String! $password: String!){
  logIn( username: $username, password: $password) {
    success
    token
    error
    id
  }
}
`;

export const signUpLoginM = gql`
mutation signUpLoginM( $username:String! $password: String!){
  signUpLoginM( username: $username, password: $password) {
    success
    token
    error
    id
  }
}
`;

export const generateUserM = gql`
mutation generateUserM( $username:String! $password: String! $userdata: String!){
  generateUserM( username: $username, password: $password, userdata: $userdata) {
    success
    token
    error
    id
  }
}
`;

const Login = () => {
   
  const [login , setLogin] = useState(userDatas().uid === ''?0:1); // 0 not login, 1: login ok, 2: need regist
  const [email , setEmail] = useState('')
  const [password , setPassword] = useState('')
  const [name , setName] = useState('')
  const [message , setMessage] = useState('')

  const [signUpLogin , {data:signuploginData , loading:signuploginLoading , error:signuploginError}] = useMutation(signUpLoginM , {
      //here i am doing something after the mutaion finished
      onCompleted(data) {
      if(data.signUpLoginM.success === true ) {

        setMessage('');
          setLogin( data.signUpLoginM.id === ''? 2:1);

          userDatas({...userDatas(), uid:data.signUpLoginM.id, uname: email});

          localStorage.setItem('exName' , email);
          localStorage.setItem('exID' , data.signUpLoginM.id);
          localStorage.setItem('userToken' , data.signUpLoginM.token);
      }
      else{
        setMessage(data.signUpLoginM.error);
      }

    }
  });

  const [generateUser , {data:generateUserData , loading:generateUserLoading , error:generateUserError}] = useMutation(generateUserM , {
    //here i am doing something after the mutaion finished
    onCompleted(data) {
    if(data.generateUserM.success === true ) {

      setMessage('');
        setLogin( data.generateUserM.id === ''? 2:1);
        
        userDatas({...userDatas(), uid:data.generateUserM.id, uname: email});

        localStorage.setItem('exName' , email);
        localStorage.setItem('exID' , data.generateUserM.id);
        localStorage.setItem('userToken' , data.generateUserM.token);
    }
    else{
      setMessage(data.generateUserM.error);
    }

  }
});

// const token = localStorage.getItem('userToken')

  const { data:{darkMode}} = useQuery(GET_DARK_MODE)

  
  const submitLogin = (e:any) => {
     e.preventDefault()
     
     if( isInvalidMatchedRegex(email, emailRegex) )
      return;


     signUpLogin({variables: {username:email , password:password}}).then((result) => {

      //this also return promise
      
        //console.log('data in object' , result)
        //const {data : {logIn  : {success , token , id}}} = result
       // console.log('user token ',token)
        //console.log('user id ',id)
        //localStorage.setItem('exID' , id);
        //localStorage.setItem('userToken' , token);
     
     }).catch(err => {
      console.log('login error',signuploginError)
        console.log(err)
     })
    
  }

  const submitCreate = (e:any) => {
    e.preventDefault()
    
    const dataJson = {
        cases:[],
        actions:[["",{type:2,action:[],rules:[]},6,""]], // cat:6(form) - type:1(assessment result)
        content:{name:name}, 
    }

    let userdata = JSON.stringify(dataJson);
    generateUser({variables: {username:email , password:password, userdata:userdata}}).then((result) => {

     //this also return promise
     
       //console.log('data in object' , result)
       //const {data : {logIn  : {success , token , id}}} = result
      // console.log('user token ',token)
       //console.log('user id ',id)
       //localStorage.setItem('exID' , id);
       //localStorage.setItem('userToken' , token);
    
    }).catch(err => {
      //console.log('login error',createUserError)
      console.log(err)
    })
   
 }

  const openUserCard = (e:any) => {
    e.preventDefault()
    const url = 'https://fd.liflo.io/slide/'+localStorage.getItem('exID');
    window.open(url, '_blank', "height=800,width=550");
  }
  
  // if(loading) return (<Spinner color="black" />)
  if(signuploginLoading) {
    console.log('loading....')
    return <CircularProgress color="primary" />
  }

  
  const isInvalidMatchedRegex = (value: String, regex: RegExp) => {
    return !String(value).toLowerCase().match(regex);
  } 
  const changeDarkMode = () => {
    console.log(darkMode);
    toggleMode(!darkMode);
  }
  const cheight = cardView().height;
  return (
    <Box component="div" sx={{ display: 'flex', flexDirection: 'column', width: '100%', bgcolor: 'background.paper'}}>
      
      {message !== '' && <Alert severity="error">{message}</Alert>}
      { login === 0 &&
        <Grid container direction="row" justifyContent="center" alignItems="flex-end" spacing={2}>
          <Grid item xs={7}>
          <h2 style={{color:darkMode === true ?"green":"red"}}>Login / SignUp</h2>
          </Grid>
          <Grid item xs={7}>
            <FormGroup>
              <InputLabel htmlFor="exampleEmail">Email</InputLabel>
              <Input type="email" onChange={(e) => setEmail(e.target.value)} value={email} name="email" />
            </FormGroup>
          </Grid>
          <Grid item xs={7}>
          <FormGroup>
            <InputLabel htmlFor="exampleEmail">Password</InputLabel>
            <Input type="password" onChange={(e) => setPassword(e.target.value)}value={password}  name="password" />
          </FormGroup>
          </Grid>
          <Grid item xs={7}>
          <Button color="success" variant="contained"  onClick={submitLogin} >login / SignUp</Button>
          </Grid>
        </Grid>
      }
      { login === 1 && 
        <Grid container direction="row" justifyContent="center" alignItems="flex-end" spacing={2}>
          <Grid item xs={10}>
            <Typography gutterBottom variant="h6" component="div">
            id : {userDatas().uname}
            </Typography>
          </Grid>
          <Grid item xs={5}>
              <Typography gutterBottom variant="h6" component="div">
              인지 검사 :
              </Typography>
          </Grid>
          <Grid item xs={3}>
              <Typography gutterBottom variant="h6" component="div">
                양호
              </Typography>
          </Grid>
          <Divider variant="middle" />
          <Grid item xs={7}>
            <Button color="success" variant="contained" onClick={openUserCard} >Open User Card</Button>
          </Grid>
          <Divider variant="middle" />
          <Grid item xs={10}>
            <Typography gutterBottom variant="body2">
              keywords
            </Typography>
            <Stack direction="row" spacing={1}>
              <Chip label="우울" />
              <Chip color="primary" label="ADHD" />
              <Chip label="발달" />
              <Chip label="치료" />
            </Stack>
          </Grid>
        </Grid>
      }
      { login === 2 && <>
        <Grid container direction="row" justifyContent="center" alignItems="flex-end" spacing={2}>
          <Grid item xs={7}>
          <h2 style={{color:darkMode === true ?"green":"red"}}>Create User</h2>
          </Grid>
          <Grid item xs={10}>
            <Typography gutterBottom variant="h6" component="div">
            {email}
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <FormGroup>
              <InputLabel htmlFor="exampleEmail">name</InputLabel>
              <Input type='text' onChange={(e) => setName(e.target.value)} value={name}  name="name" />
            </FormGroup>
          </Grid>
          <Grid item xs={7}>
          <Button color="success" variant="contained" onClick={submitCreate} >Create</Button>
          </Grid>
        </Grid>
      </>}
      {signuploginData && <Alert severity="success">{signuploginData?.login?.msg}</Alert>}
      {darkMode === true ? (<Button color="primary" onClick={changeDarkMode}>Disable DarkMode</Button>):(<Button color="error" onClick={changeDarkMode}>Enable DarkMode</Button>)}
    </Box>
  )
}

export default function User(props:any) {
  return <Login/>
}

/**
                {props.data.assessment.map((v:any,i:number)=>(
                  <Typography color="text.secondary" key={i} variant="body1">{v.output}:{v.point}</Typography>
                )) }
 */