import React,{  Suspense, lazy } from 'react';


export default function Examination(props:any) {

  const RotationChild = lazy(() => import('../Exam/RotationChild'));
  const EmotionChild = lazy(() => import('../Exam/EmotionChild'));
  const MotorChild = lazy(() => import('../Exam/MotorChild'));
  const SequenceChild = lazy(() => import('../Exam/SequenceChild'));
  const NBackChild = lazy(() => import('../Exam/NBackChild'));
  const RotationAdult = lazy(() => import('../Exam/RotationAdult'));
  const NBackAdult = lazy(() => import('../Exam/NBackAdult'));
  const EmotionAdult = lazy(() => import('../Exam/EmotionAdult'));
  const MotorAdult = lazy(() => import('../Exam/MotorAdult'));
  const SequenceAdult = lazy(() => import('../Exam/SequenceAdult'));
  return (
      <Suspense fallback={null}>              
        { props.src === "../Exam/RotationChild" && <RotationChild nextCard={props.nextCard}/>}
        { props.src === "../Exam/EmotionChild" && <EmotionChild  nextCard={props.nextCard}/>}
        { props.src === "../Exam/MotorChild" && <MotorChild  nextCard={props.nextCard}/>}
        { props.src === "../Exam/SequenceChild" && <SequenceChild  nextCard={props.nextCard}/>}
        { props.src === "../Exam/NBackChild" && <NBackChild  nextCard={props.nextCard}/>}
        { props.src === "../Exam/RotationAdult" && <RotationAdult nextCard={props.nextCard}/>}
        { props.src === "../Exam/NBackAdult" && <NBackAdult  nextCard={props.nextCard}/>}
        { props.src === "../Exam/EmotionAdult" && <EmotionAdult  nextCard={props.nextCard}/>}
        { props.src === "../Exam/MotorAdult" && <MotorAdult  nextCard={props.nextCard}/>}
        { props.src === "../Exam/SequenceAdult" && <SequenceAdult  nextCard={props.nextCard}/>}
      </Suspense>
  )
}
