import { makeVar } from '@apollo/client'

// --- user data ---
interface userDataType {
    type: number;
    code: string;
    uname: string;
    uid: string;
    width: number;
    height: number;
    ratio: number;
    reSize: boolean;
}
export const userDatas = makeVar<userDataType>({type: 0, code: '', uname:'', uid:'', width:1024,height:768,ratio:64,reSize:true});
